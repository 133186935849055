import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {SectionHeading} from '../Common/common.style';
import {GallerySection,GalleryImageWrapper} from './gallery.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const Gallery = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            interiorJson {
                Gallery {
                    DelayConstant
                    SectionHeading
                    GalleryImageWrapper{
                        GalleryImage{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    return (
        <GallerySection id="galleryContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interiorJson.Gallery.DelayConstant}>
                            <SectionHeading>
                                {JSONData.interiorJson.Gallery.SectionHeading}
                            </SectionHeading>
                        </Fade>
                    </Col>
                </Row>
            </Container>
            <Fade bottom delay={1*JSONData.interiorJson.Gallery.DelayConstant}>
            <GalleryImageWrapper>
            {
                JSONData.interiorJson.Gallery.GalleryImageWrapper.map((item,idx) => {
                    return <GatsImg 
                        fluid={item.GalleryImage.childImageSharp.fluid} 
                        className="GalleryImage"
                        imgStyle={{
                            marginBottom:"0px",
                        }}
                        alt=""
                    />
                })
            }
            </GalleryImageWrapper>
            </Fade>
        </GallerySection>
    );
}

export default Gallery;