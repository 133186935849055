import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {FeaturesSection,FeatureCustomContainer,
    FeatureCol,FeatureText} from './featuers.style' 
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            interiorJson {
                KeyFeatures {
                    DelayConstant
                    SectionHeading
                    FeatureCustomContainer{
                        FeatureImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        FeatureText
                    }
                }
            }
        }
    `);
    return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interiorJson.KeyFeatures.DelayConstant}>
                            <SectionHeading>{JSONData.interiorJson.KeyFeatures.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <FeatureCustomContainer>
                <Row>
                { 
                    JSONData.interiorJson.KeyFeatures.FeatureCustomContainer.map((item,idx) => {
                    return <Col md="3" sm="6" xs="6">
                        <Fade bottom delay={(idx+1)*JSONData.interiorJson.KeyFeatures.DelayConstant}>
                            <FeatureCol>
                                    {
                                        (idx % 2 === 1) &&
                                        <GatsImg 
                                            fluid={item.FeatureImg.childImageSharp.fluid} 
                                            className="FeatureImg"
                                            alt=""
                                        />
                                    }

                                    {
                                        (idx % 2 === 0) &&
                                        <GatsImg 
                                            fluid={item.FeatureImg.childImageSharp.fluid} 
                                            className="FeatureImgTopPadded"
                                            alt=""
                                        />
                                    }
                                    <FeatureText>{item.FeatureText}</FeatureText>
                            </FeatureCol>
                        </Fade>
                    </Col>
                    })
                }
                </Row>
                </FeatureCustomContainer>
            </Container>
        </FeaturesSection>
    );
}

export default Features