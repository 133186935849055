import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {QualitySection,QualitySectionHeadingWrapper,QualityCustomContainer,
    QualityColBig,QualityColSmall,QualityColBigHeading,
    QualityColSmallHeading,QualityColPara,QualityColBigFigure,QualityColSmallFigure} from './quality.style';
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const Quality = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            interiorJson {
                Quality {
                    DelayConstant
                    SectionHeading
                    QualityColBig{
                        QualityColBigImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        QualityColBigHeading
                        QualityColPara
                    }
                    QualityColSmall1{
                        QualityColSmallImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        QualityColSmallHeading
                        QualityColPara
                    }
                    QualityColSmall2{
                        QualityColSmallImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        QualityColSmallHeading
                        QualityColPara
                    }
                }
            }
        }
    `);

    return (
        <QualitySection>
            <Container>
                <Row>
                    <Col md="12">
                        <QualitySectionHeadingWrapper>
                            <Fade bottom delay={1*JSONData.interiorJson.Quality.DelayConstant}>
                                <SectionHeading>
                                    {JSONData.interiorJson.Quality.SectionHeading}
                                </SectionHeading>
                            </Fade>
                        </QualitySectionHeadingWrapper>
                        <Fade bottom delay={1*JSONData.interiorJson.Quality.DelayConstant}>
                            <QualityCustomContainer>
                                <QualityColBig>
                                <QualityColBigFigure>
                                    <GatsImg 
                                        fluid={JSONData.interiorJson.Quality.QualityColBig.QualityColBigImg.childImageSharp.fluid} 
                                        className="QualityColBigImg"
                                        alt=""
                                    />
                                    </QualityColBigFigure>
                                    <QualityColBigHeading>
                                    {JSONData.interiorJson.Quality.QualityColBig.QualityColBigHeading}
                                    </QualityColBigHeading>
                                    <QualityColPara>
                                    {JSONData.interiorJson.Quality.QualityColBig.QualityColPara}
                                    </QualityColPara>
                                </QualityColBig>

                                <QualityColSmall marginProp="0px 40px">
                                    <QualityColSmallFigure >
                                        <GatsImg 
                                            fluid={JSONData.interiorJson.Quality.QualityColSmall1.QualityColSmallImg.childImageSharp.fluid} 
                                            className="QualityColSmallImg"
                                            alt=""
                                        />
                                    </QualityColSmallFigure>
                                    <QualityColSmallHeading>
                                        {JSONData.interiorJson.Quality.QualityColSmall1.QualityColSmallHeading}
                                    </QualityColSmallHeading>
                                    <QualityColPara>
                                    {JSONData.interiorJson.Quality.QualityColSmall1.QualityColPara}
                                    </QualityColPara>
                                </QualityColSmall>
                            
                                <QualityColSmall>
                                    <QualityColSmallFigure>
                                        <GatsImg 
                                            fluid={JSONData.interiorJson.Quality.QualityColSmall2.QualityColSmallImg.childImageSharp.fluid} 
                                            className="QualityColSmallImg"
                                            alt=""
                                        />
                                    </QualityColSmallFigure>
                                    <QualityColSmallHeading>
                                        {JSONData.interiorJson.Quality.QualityColSmall2.QualityColSmallHeading}
                                    </QualityColSmallHeading>
                                    <QualityColPara>
                                    {JSONData.interiorJson.Quality.QualityColSmall2.QualityColPara}
                                    </QualityColPara>
                                </QualityColSmall>
                            </QualityCustomContainer>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </QualitySection>
    );
}

export default Quality;