import React from 'react';
import {BannerSection,DownArrow,
    BannerContents,ArrowCol,
    BannerContentLeft,
    BannerBtn,
    BannerH3,
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';
import Fade from "react-reveal/Fade";

import { useStaticQuery, graphql } from 'gatsby';
const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            interiorJson {
                Banner {
                    BannerBtn
                    BannerH3
                    DelayConstant
                }
            }
        }
    `);
    return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <Fade bottom delay={1*JSONData.interiorJson.Banner.DelayConstant}>
                            <BannerH3>{JSONData.interiorJson.Banner.BannerH3}</BannerH3>
                            <BannerBtn href="#">{JSONData.interiorJson.Banner.BannerBtn}</BannerBtn>
                        </Fade>
                    </BannerContentLeft>
                    <ArrowCol>
                        <DownArrow/>
                    </ArrowCol>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;