import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    
    body{
        font-family: 'Noto Sans', sans-serif;
        font-weight:500;
    }
    
    h1,h2,h3,h4,h5,h6{
        font-family: 'Muli', sans-serif;
        font-weight: 700;
    }
`;

export default GlobalStyle;